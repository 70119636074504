<template>
  <div class="flambu-story">
    <b-container>
      <p class="landing-sub-title bold-text text-center mb-4">What is Flambu? </p>
      <p class="mb-4">
        Flambu is a social platform for sharing, borrowing, buying and selling items from nearby people. Most of the items we own are idle 90% of the time, so why not rent them out and make an extra income? Additionally, checking out items near you might bring you unexpected and cool experiences!
      </p>
      <p class="mb-4">
        Our vision is to provide a solution to reduce overconsumption; one of humanity’s greatest challenges today. So we’re making it quick and easy for people to share their belongings. Our main purpose is to create a more sustainable environment with the help of a strong community. We wish to create a community highly involved in the cause.
      </p>
      <p class="landing-sub-title bold-text text-center mb-4">Our story </p>
      <p class="mb-4">The story of Flambu began when we wanted to go camping, but didn’t have the necessary equipment. During preparation, we were overwhelmed by the overall high cost of purchasing all the camping gear that we needed, such as tents, chairs, tables, and shade. We even thought it would be nice to have a generator for electricity, but couldn’t afford it.</p>
      <p class="mb-4">So, we bought everything we could for that trip, and never went camping again after that. We realized that this case is relevant for many other situations as well! One time experiences, trying a new hobby or going on a trip is just spending a lot of money and then storing the items until they get dusty.</p>
      <p class="mb-4">
        As of today, it is not the norm to rent out stuff for those situations, which is neither efficient, nor sustainable! So we thought; what if there was a place where people could rent the items they need in order to save time and money, also lend their unused items to others and make some extra money. This sounded like a Win-Win for everyone and also for the environment, so we started working on it right after the camping.
      </p>
      <p class="mb-4">But why Flambu? It goes back to the camping experience. While we were discussing our idea, we were sitting around a campfire. It was the only source of light and we thought about how if you share a flame, it becomes even brighter. So we came up with the name “Flambu”, originating from the french word for torch “flambeau”</p>
      <p class="mb-4">Join us today by downloading our application and start sharing what you have and rent what you need!</p>
      <!-- <b-button class="pink-round-btn" href="https://form.typeform.com/to/nfIxSx5L?typeform-medium=embed-snippet" target="_blank">Signup for early access</b-button> -->
      <b-img :src="appStoreImg" class="mr-lg-5 mr-sm-3 mr-2 app-link"></b-img>
      <b-img :src="googlePlayStoreImg" class="app-link"></b-img> 
    </b-container>
  </div>
</template>

<script>
export default {
  name: "FlambuStory",
  components: {},
  methods: {},
  data: function() {
    return {};
  },
};
</script>

<style lang="scss">
@import "@/assets/custom/_variables.scss";
.flambu-story {
  background-image: url("../assets/img/story-bg.png");
  padding: 4em 0px 6em 0px;
}
</style>
