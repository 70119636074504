<template>
    <b-container class="try-flumbu-box">
      <div id="try-flambu" class="try-flumbu-title">
        Try <b-img :src="flambuLogImg" class="flambu-logo"></b-img> yourself and share your own flame
      </div>
      <b-img :src="tryFlambuMobile" class="try-flambu-mobile"></b-img>
      <div class="text-center mt-5">
        <a href="https://flambu.onelink.me/6s7S/276ba288"><b-img :src="appStoreImg" class="mr-lg-5 mr-sm-3 mr-2 app-link"></b-img></a>
        <a href="https://flambu.onelink.me/XBny/3e759a05"><b-img :src="googlePlayStoreImg" class="app-link"></b-img></a>
      </div>
    </b-container>
</template>

<script>

import tryFlambuMobile from "@/assets/img/try-flambu-mobile.webp";
import flambuLogImg from "@/assets/img/flambu-logo.png";
import googlePlayStoreImg from '@/assets/img/google-playstore.png';
import appStoreImg from '@/assets/img/app-store.png';

export default {
  name: "TryFlumbuMobile",
  components: {},
  methods: {
  },
  data: function() {
    return {
        tryFlambuMobile,
        flambuLogImg,
        googlePlayStoreImg,
        appStoreImg
    };
  }
};
</script>

<style lang="scss">
@import "@/assets/custom/_variables.scss";

.try-flumbu-box {
    margin-top: 5em;
    margin-bottom: 7em;
    .flambu-logo {
        max-height: 32px;
        margin: 0px 8px;
    }
    .try-flumbu-title {
        font-size: 38px;
        font-weight: bold;
        color: #313131;
        align-items: center;
    }
    .try-flambu-mobile {
        max-width: 60%;
    }
    .app-link {
        max-width: 300px;
    }
}

</style>
