export function loadScript(url, callback) {
    var script = document.createElement('script');
    script.type = 'text/javascript';
    if (script.readyState) {
      script.onreadystatechange = function () {
        if (script.readyState == 'loaded' || script.readyState == 'complete') {
          script.onreadystatechange = null;
          callback();
        }
      };
    } else {
      script.onload = function () {
        callback();
      };
    }
    script.src = url;
    document.getElementsByTagName('head')[0].appendChild(script);
    return;
  }

  export function addExternalJs(src) {
    var script = document.createElement("script");
    script.src = src;
    document.getElementsByTagName("head")[0].appendChild(script);
  }

  export function isExternalJsAlreadyDefined(link) {
    var arrays = document.getElementsByTagName("script");
    for (var i = 0; i < arrays.length; i++) {
      var item = arrays[i];
      if (item.src == link) {
        return true;
      }
    }
    return false;
  }