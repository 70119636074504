<template>
  <div class="home-page">
    <div class="share-flame">
      <b-container>
        <div class="text-center">
          <b-img :src="flameLog" class="flame-logo" />
        </div>
        <div class="flame-content">
          <p class="landing-big-title">
            Share. Borrow. Experience.
          </p>
          <div class="d-sm-flex home-search-box" align-content="between">
            <b-input-group class="home-search-group">
              <template #prepend>
                <b-img :src="searchIcon" />
              </template>
              <b-form-input
                placeholder="What are you looking for??"
                 v-on:keyup.enter="searchItems()" v-model="searchKey"
              ></b-form-input>
            </b-input-group>
            <b-button class="pink-round-btn" @click="searchItems()">Search</b-button>
          </div>
        </div>
      </b-container>
    </div>
    <Categories></Categories>
    <b-container>
      <b-row class="why-flambu">
        <b-col lg="7">
          <p class="landing-sub-title bold-text mb-5">What we do</p>
          <p class="faint">
            Think about how you would do things in the past: learn a new hobby, film a crazy trip, or want to change your home a little bit. For each of these examples you would have to buy something, but you wouldn’t be sure if you will ever use it again. In that case you spend unnecessary money and space in your apartment.
          </p>
          <p class="faint mt-4">
            With the help of Flambu, you won’t need to compromise on having the best experiences anymore. Flambu guides you into having affordable experiences, with the benefit of having a clutter-free home by offering you the option of renting items. With the wide range of items provided in the Flambu App/Website, you can schedule to rent an item from people around you for as long as you need and for the fraction of the buying price. Obviously, this goes both ways: If you have items at home you don’t use on a daily basis, you can upload and share them with the people around you.
          </p>
        </b-col>
        <b-col lg="5">
          <b-img :src="flamePhoneImg"></b-img>
        </b-col>
      </b-row>
      <div class="flambu-intro">
        <b-row>
          <b-col lg="5" class="pr-lg-4">
            <b-img :src="enjoyWithoutBuyingImg"></b-img>
          </b-col>
          <b-col lg="7" class="pl-lg-5">
            <p class="landing-sub-title bold-text mb-5">Try before buying</p>
            <p class="faint">
              Our world is full of options and opportunities, which makes it hard to commit. Between all the different brands, products, colours and versions, it can be difficult to make the right choice - research and reading reviews can be tiring, and in the end we might not even be satisfied.
            </p>  
            <p class="faint mt-4"> 
              Using Flambu as a “try before buy” option can help eliminate stressful decisions, impulse purchases and regrets. We offer a new way of living, getting away from the consumerist lifestyle that we were all raised to be in, and instead to become more informed about the things we want to buy.
            </p>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="7" class="pr-lg-5">
            <p class="landing-sub-title bold-text mb-5">Travel light</p>
            <p class="faint">
              After more than a year of zero or only domestic travels, we are all longing for a vacation far, far away. Whether you go on a family vacation, a skiing trip or backpacking, knowing that you can use Flambu at your destination makes everything simple, you can effort-free search for the items you need only for your vacation and rent them from local people in your destination.
            </p>
           </b-col>
          <b-col lg="5" class="pl-lg-5">
            <b-img :src="travelLightImg"></b-img>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="4" class="pr-lg-5">
            <b-img :src="substainableImg"></b-img>
          </b-col>
          <b-col lg="8" class="pl-lg-5">
            <p class="landing-sub-title bold-text mb-5">Live minimalist</p>
            <p class="faint">Last but definitely not least, let’s think about the environment: Most of the stuff that we buy and accumulate is idle nearly all of the time. By sharing our items with others, we don’t need to buy/ own everything. It will be accessible to us through Flambu.</p>
            <p class="faint mt-4">By being part of the sharing economy we can reduce productions, hence resources, and waste! Join Flambu today, be part of our community. Share what you have and rent what you need, to keep our planet clean and green.</p>
          </b-col>
        </b-row>
      </div>
    </b-container>
    <TryFlumbuMobile></TryFlumbuMobile>
  </div>
</template>

<script>
import flameLog from "@/assets/img/flame-logo.png";
import searchIcon from "@/assets/img/lupa.png";
import flamePhoneImg from "@/assets/img/flame-phone.png";
import enjoyWithoutBuyingImg from "@/assets/img/undraw_road_sign_mfpo.svg";
import travelLightImg from "@/assets/img/travel-light.png";
import substainableImg from "@/assets/img/sustainable.png";

import TryFlumbuMobile from "../components/TryFlumbuMobile.vue";
import Categories from "../components/Categories.vue";

export default {
  name: "Home",
  metaInfo() {
      return { 
          title: "Flambu | Share. Borrow. Experience.",
          meta: [
              { 
                name: 'description', 
                content:  'Flambu is your place to go to find the items you need to borrow or rent from your neighbors.'},
              { property: 'og:title', content: "Flambu | Share. Borrow. Experience."},
              { property: 'og:site_name', content: 'Flambu'},
              {property: 'og:type', content: 'website'},
              {property: 'og:img', itemprop: "image", content: "/img/icons/favicon-32x32.png"},
              {name: 'robots', content: 'index,follow'} 
          ]
      }
  },
  components: {
    TryFlumbuMobile,
    Categories
  },
  methods: {
    async searchItems() {
      this.$router.push('/items?searchKey=' + this.searchKey);
    }
  },
  mounted() {

  },
  data: function() {
    return {
      flameLog,
      searchIcon,
      flamePhoneImg,
      enjoyWithoutBuyingImg,
      travelLightImg,
      substainableImg,
      searchKey: ''
    };
  },
};
</script>
<style lang="scss">
.home-page {
  .share-flame {
    background-image: url("../assets/img/home-share-flame.webp");
    min-height: 45vw;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    color: white;
    .container {
      padding-bottom: 30px;
    }
  }
  .why-flambu {
    .landing-sub-title {
      margin-top: 100px;
    }
    img {
      height: calc(100% + 90px);
      margin-top: -90px;
    }
  }
  .flambu-intro {
    margin-top: 120px;
    margin-bottom: 120px;
    .row {
      margin-bottom: 60px;
    }
    img {
      max-width: 100%;
    }
  }
}

.home-search-group {
  background: white;
  padding: 0.375rem 0.75rem;
  margin-right: 20px;
  border-radius: 10px;
  input {
    border: none;
    outline: none!important;
    margin-left: 5px;
    padding: 0px 0px 0px 15px;
    font-weight: bold;
    &:active,
    &:focus {
      outline: none!important;
      box-shadow: none;
    }
  }
  .input-group-prepend {
    align-items: center;
    img {
      height: 20px;
    }
  }
}
.flame-logo {
  width: 200px;
  margin-top: 60px;
}
.flame-content {
  width: 54em;
  margin: auto;
}
</style>
