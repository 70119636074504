<template>
  <div class="related-items">
    <p class="landing-sub-title mt-5">
        Related Items
    </p>
    <b-row class="mb-5">
        <b-col sm="4" v-for="(item,index) in items" :key="index">
          <div class="relation-item-div px-lg-4 px-md-3 px-sm-2 text-center">
            <b-img :src="item.publicImagePath" @click="goToRelatedItem(item)"></b-img>       
          </div>
                 
        </b-col> 
    </b-row>
    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      align="center"    
      prev-text="< Previous"
      next-text="Next >"
      prev-class="custom-pagination-prev"
      next-class="custom-pagination-next"
      page-class="custom-pagination-btn"
      first-number
      last-number
    ></b-pagination>
  </div>
</template>

<script>

export default {
  name: "ItemReviews",
  components: {},
  props: ["data"],
  methods: {
    goToRelatedItem(item) {
      this.$router.push('/item/' + item.$key);
    }
  },
  data: function() {
    return {
      perPage: 3,
      currentPage: 1
      
    };
  },
  computed: {
    rows() {
      return this.data.length;
    },
    items() {
      let startIndex = (this.currentPage - 1) * this.perPage;
      let endIndex = startIndex + this.perPage;
      if (this.rows < endIndex) endIndex = this.rows;
      return this.data.slice(startIndex, endIndex);
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/custom/_variables.scss";
.related-items {
    margin-top: 60px;
    margin-bottom: 50px;
    img {
        width: 100%;
        height: 300px;
        border-radius: 12px;
        object-fit: cover;
    }
}
</style>
