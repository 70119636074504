<template>
  <b-row class="blog-content">
    <b-col sm="10" offset-sm="1">
      <h1 class="landing-big-title text-center mb-5">
        Discover the latest <span class="pink-color">news</span>
      </h1>

      <div
        id="retainable-rss-embed"
        data-rss="https://medium.com/feed/flambu"
        data-maxcols="3"
        data-layout="grid"
        data-poststyle="inline"
        data-readmore="Read the rest"
        data-buttonclass="btn pink-round-btn text-white"
        data-offset="-100"
      ></div>
    </b-col>
  </b-row>
</template>

<script>
import { addExternalJs, isExternalJsAlreadyDefined } from "@/utility/utility";

export default {
  name: "Blog",
  metaInfo() {
      return { 
          title: "Flambu | Share. Borrow. Experience.",
          meta: [
              { 
                name: 'description', 
                content:  'Flambu is your place to go to find the items you need to borrow or rent from your neighbors.'},
              { property: 'og:title', content: "Flambu | Share. Borrow. Experience."},
              { property: 'og:site_name', content: 'Flambu'},
              {property: 'og:type', content: 'website'}, 
              {property: 'og:img', itemprop: "image", content: "/img/icons/favicon-32x32.png"},
              {name: 'robots', content: 'index,follow'} 
          ]
      }
  },
  components: {},
  mounted() {
    const retainableUrl =
      "https://www.twilik.com/assets/retainable/rss-embed/retainable.js";
    const retainableRssUrl =
      "https://www.twilik.com/assets/retainable/rss-embed/retainable-rss-embed.js";
    if (!isExternalJsAlreadyDefined(retainableUrl)) {
      addExternalJs(retainableUrl);
    }
    if (!isExternalJsAlreadyDefined(retainableRssUrl)) {
      addExternalJs(retainableRssUrl);
    }
  },
  methods: {},
  data: function() {
    return {};
  },
};
</script>
<style lang="scss">
.blog-content {
  margin: 0px !important;
  padding: 3em 0em;
}
</style>
