import * as firebase from '../../firebase-config'
import { Currency } from '../models/currency';
import store from '../store';

export async function getCurrenciesList() {
    let currencies = store.state.currencies;
    if (currencies.length == 0) {
        currencies = [];
        let collectionRef = firebase.currenciesCollection;
        const {docs} = await collectionRef.get();
        currencies = docs.map(doc=> new Currency({...doc.data(),$key: doc.id}) );
        store.commit('updateCurrencies', currencies);
    }
    return currencies;
}

export function getUserSelectedCurrency() {
    return store.state.userSelectedCurrency;
}