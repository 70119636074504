<template>
  <div class="items-page">
    <div class="category-search-box" :class="currentCategoryId=='all'? 'category-all': ''" :style="getTransportationBoxStyle()">
      <b-container v-if="loaded">
        <div v-if="category" class="text-center"> 
          <b-img :src="flameLog" class="flame-logo"/> 
        </div>
        <div class="flame-content">
          <p v-if="category" class="landing-big-title">
            {{category.name}}
          </p>
          <div class="d-sm-flex home-search-box" align-content="between"> 
            <b-input-group class="home-search-group">
              <template #prepend>
                <b-img :src="searchIcon"/> 
              </template>
              <b-form-input placeholder="What are you looking for??" v-on:keyup.enter="searchKeySubmitted()" v-model="searchKey"></b-form-input>
            </b-input-group>  
            <b-button class="pink-round-btn" @click="searchKeySubmitted()">Search</b-button>
          </div>
        </div>
      </b-container>
    </div>
    <b-container>
      <ItemsTable :data="items" :loaded="loaded"></ItemsTable>
      <DescribeNeedForm class="mt-5"></DescribeNeedForm>
    </b-container>
    
    <TryFlumbuMobile></TryFlumbuMobile>
  </div>
</template>

<script>
import { getItemListWithCategory } from '@/services/ItemsService';
import { getCategoryWithId } from '@/services/CategoryService';
import TryFlumbuMobile from '../components/TryFlumbuMobile.vue';
import ItemsTable from '../components/Table/ItemsTable.vue';
import DescribeNeedForm from '../components/Form/DescribeNeedForm.vue';

import flameLog from "@/assets/img/flame-logo.png";
import searchIcon from "@/assets/img/lupa.png";

export default {
  name: 'Items',
  metaInfo() {
      return { 
          title: "Flambu | Share. Borrow. Experience.",
          meta: [
              { 
                name: 'description', 
                content:  this.getPageDescription()},
                { 
                name: 'og:description', 
                content:  this.getPageDescription()},
              { property: 'og:title', content: "Flambu | Share. Borrow. Experience."},
              { property: 'og:site_name', content: 'Flambu'},
              {property: 'og:type', content: 'website'},
              {property: 'og:img', itemprop: "image", content: "/img/icons/favicon-32x32.png"},
              {name: 'robots', content: 'index,follow'} 
          ]
      }
  },
  components: {
    TryFlumbuMobile,
    ItemsTable,
    DescribeNeedForm
  },
  mounted() {
    this.initData();
  },
  watch:{
    $route (){
      this.category = null;
      this.loaded = false;
      this.currentCategoryId = 'all';
      this.initData();
    }
  }, 
  methods: {
    getPageDescription() {
      let description = "Search the items you need in hundreds of items shared in your neighborhood.";
      if (this.category) {
        description = `Rent the best ${this.category.name} items with Flambu for the best prices. \n ` + description;
      }
      return description;
    },
    searchKeySubmitted() {
      let query = this.$route.query;
      this.$router.replace(this.$route.path + '?category=' + (query.category || '') + '&searchKey=' + this.searchKey).catch(()=>{});
    },
    async initData() {
      if(this.$route.query.category) this.currentCategoryId = this.$route.query.category;
      if(this.$route.query.searchKey) this.searchKey = this.$route.query.searchKey;
      if(this.currentCategoryId!='all') this.category = await getCategoryWithId(this.currentCategoryId)
      await this.searchItems();
    },
    async searchItems() {
      try {
        this.items = await getItemListWithCategory(this.currentCategoryId,this.searchKey);
        this.loaded = true;
      }catch(e) {
        console.log(e);
      }
    },
    getTransportationBoxStyle() {
      let style= {};
      if(this.category) {
        style['background-image'] = 'url(' + this.category.categoryImagePath + ')';
      }
      return style;
    }
  },
  data: function() {
    return {
      currentCategoryId: 'all',
      searchKey: '',
      category: null,
      items: [],
      flameLog,
      searchIcon,
      loaded: false,
    };
  },
}
</script>

<style lang="scss">
  .category-search-box {
    min-height: 35vw;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    color: white;
    .container {
      padding-bottom: 30px;
    }
    &.category-all {
      min-height: 10em;
      .container {
        padding-top: 3em;
        .home-search-group {
          border: 1px solid #c7c7c7;
        }
      }
    }
  }
</style>