import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'
import { auth } from '../firebase-config';
import BootstrapVue from 'bootstrap-vue'
import * as VueGoogleMaps from "vue2-google-maps";
import titleMixin from './mixins/titleMixin';

import './assets/main.scss'
import './assets/fonts/font-awesome/css/all.min.css'

Vue.mixin(titleMixin)
Vue.use(BootstrapVue)
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.GOOGLE_MAPS_KEY,
    libraries: "places" // necessary for places input
  }
});

Vue.config.productionTip = false

auth.onAuthStateChanged(user => {
  if (user) {
    new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  }
})