<template>
  <div>
    <div>
        <b-img :src="passionLeadUsImg" class="passion-lead-us"></b-img>
    </div>
    <FlambuAnalytics></FlambuAnalytics>
    <FlambuStory></FlambuStory>
    <TryFlumbuMobile></TryFlumbuMobile>
  </div>
</template>

<script>

import TryFlumbuMobile from '../components/TryFlumbuMobile.vue';
import FlambuAnalytics from '../components/FlambuAnalytics.vue';
import FlambuStory from '../components/FlambuStory.vue';

import passionLeadUsImg from '@/assets/img/passion-lead-us.png';

export default {
  name: 'About',
  metaInfo() {
      return { 
          title: "Flambu | Share. Borrow. Experience.",
          meta: [
              { 
                name: 'description', 
                content:  'Flambu is your place to go to find the items you need to borrow or rent from your neighbors.'},
              { property: 'og:title', content: "Flambu | Share. Borrow. Experience."},
              { property: 'og:site_name', content: 'Flambu'},
              {property: 'og:type', content: 'website'},  
              {property: 'og:img', itemprop: "image", content: "/img/icons/favicon-32x32.png"},
              {name: 'robots', content: 'index,follow'} 
          ]
      }
  },
  components: {
    TryFlumbuMobile,
    FlambuAnalytics,
    FlambuStory
  },
  data: function() {
    return {
      passionLeadUsImg
    };
  }
}
</script>

<style lang="scss">
.passion-lead-us {
    max-width: 100%;
}
</style>