import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
// firebase init - add your own config here
const firebaseConfig = {
    apiKey: process.env.API_KEY,
    authDomain: process.env.AUTH_DOMAIN,
    projectId: process.env.PROJECT_ID,
    storageBucket: process.env.STORAGE_BUCKET,
    messagingSenderId: process.env.MESSAGING_SENDER_ID,
    appId: process.env.APP_ID,
    measurementId: process.env.MEASUREMENT_ID
}
firebase.initializeApp(firebaseConfig)

// utils
const db = firebase.firestore()
const auth = firebase.auth()
auth.signInAnonymously()

const categoryCollection = db.collection('categories')
const itemCollection = db.collection('items')
const reviewCollection = db.collection('itemReviews')
const ownerReviewCollection = db.collection('itemOwnerReviews')
const currenciesCollection = db.collection('currencies')

// export utils/refs
export {
  db,
  auth,
  categoryCollection,
  itemCollection,
  reviewCollection,
  ownerReviewCollection,
  currenciesCollection
}