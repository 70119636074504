export class Currency {
    $key = '';
    conversionRates = {};
    name = '';
    sign = '';

    constructor(currency) {
        currency = currency || {};
        this.$key = currency.$key || '';
        this.conversionRates = currency.conversionRates || {};
        this.name = currency.name || '';
        this.sign = currency.sign || '';
    }
}