import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    currencies: [],
    userSelectedCurrency: {"$key":"USD","conversionRates":{"TRY":8.63,"ILS":3.28,"EUR":0.84,"GBP":0.72},"name":"US Dollar","sign":"$"}
  },
  mutations: {
    updateCurrencies (state, currencies) {
      state.currencies = currencies;
    },
    updateUserSelectedCurrency (state, currency) {
      state.userSelectedCurrency = currency
    }
  },
  actions: {
  },
  modules: {
  }
})
