<template>
  <div class="footer">
    <b-container fluid="xl">
      <b-row>
        <b-col lg="3" class="link-boxes mr-5"> 
            <p class="mb-3">Product</p>
            <ul class="link-items">
                <!-- <li>Learn</li>
                <li>Guides</li>
                <li>References</li> -->
                <li><a href="https://app.flambu.com/faq" target="_blank">FAQ</a></li>
                <li><a href="https://app.flambu.com/privacy" target="_blank">Privacy Policy</a></li>
                <li><a href="https://app.flambu.com/terms" target="_blank">Terms & Conditions</a></li>
            </ul>
        </b-col>
        <b-col lg="3" class="link-boxes mr-5">
            <p class="mb-3">Connect</p>
            <div class="icons mb-5">
                <a class="fab fa-linkedin-in mr-5" href="https://www.linkedin.com/company/flambu" target="_blank"></a>
                <a class="fab fa-facebook-f mr-5" href="https://www.facebook.com/flambushare" target="_blank"></a> 
                <a class="fab fa-instagram mr-5" href="https://www.instagram.com/flambu_share/" target="_blank"></a>
                <a class="fab fa-twitter mr-5" href="https://www.twitter.com/flambu_share/" target="_blank"></a>
            </div>
        </b-col>
        <!-- <b-col lg="3" class="link-boxes mr-5"> 
            <p class="mb-3">Team</p>
            <ul class="link-items">
                <li @click="goToRouter('About')">About US</li>
                <li>Careers</li>
                <li>Remote Work</li>
                <li>Partners</li>
            </ul>
        </b-col>
        <b-col lg="3" class="link-boxes mr-5"> 
            <p class="mb-3">Resources</p>
            <ul class="link-items">
                <li>Help Center</li>
                <li>Community</li>
                <li>Customer stories</li>
                <li>FAQ's</li>
            </ul>
        </b-col>
        <b-col>
            <p> 
                info@flambu.com
            </p> 
        </b-col> -->
      </b-row>
      <div class="mt-5 mb-3 text-center">
        Created with ❤️  in Tel  Aviv <br/>
        Copyright      2021 Flambu. All Rights Reserved
      </div>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "Footer",
  components: {},
  methods: {
    goToRouter(routeName) {
      this.$router.push({ name: routeName });
    },
  },
  data: function() {
    return {};
  },
};
</script>

<style lang="scss">
@import "@/assets/custom/_variables.scss";

.footer {
    background: $highlited-color !important;
    padding-top: 60px;
    padding-bottom: 15px;
    color: white;
    font-size: 13px;
    .link-boxes {
        border-top: 2px solid white;
        padding-top: 15px;
        padding-bottom: 15px;
        p {
            font-size: 16px;
        }
        .link-items {
            padding: 0px;
            margin: 0px;
            list-style: none;
            letter-spacing: 0.14px;
            line-height: 2;
        }
    }
    .icons {
        justify-content: space-between;
        display: flex;
        font-size: 28px;
    }
    li {
        cursor: pointer;
    }
    a {
        color: #ffffff;
    }
    a:hover {
        text-decoration: none;;
    }
}
@media (min-width: 1440px) {
    .footer {
        .container-xl {
            max-width: 1320px;
        }
    }
}
</style>
